import styled from "styled-components";
import { ShowoffArrow, ShowoffArrowContainer } from "./showoff-arrow-container";
import { Subtitle, TertiaryTitle, Title } from "../../general/style-components";

import { Hero } from "./hero";
import { TeamBlock } from "./team-block";
import { size32, spacing12, spacing32 } from "@litbase/alexandria";
import { theme } from "../../../themes/theme-types";
import { ReferencesBlock } from "./references-block";

export function LandingPage() {
  return (
    <Body>
      <Section $background={theme.gray700} style={{ zIndex: 5 }}>
        <Hero />
      </Section>
      <Section $background={theme.gray800} style={{ zIndex: 4 }}>
        <Title>Our projects</Title>
        <ReferencesBlock />
      </Section>
      <Section $background={theme.gray700} style={{ zIndex: 3 }}>
        <Title>The team</Title>
        <TeamBlock />
      </Section>
    </Body>
  );
}

const Section = styled.div<{ $background: string }>`
  background: ${({ $background }) => $background};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: ${spacing12};
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background: ${({ $background }) => $background};
    bottom: calc(-${spacing12} + 1px);
  }
  & + & {
    padding-top: ${spacing12};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
