import { Subtitle, TertiaryTitle } from "../../general/style-components";
import { ShowoffArrow, ShowoffArrowContainer } from "./showoff-arrow-container";
import aycmImage from "../../../assets/images/aycm.png";
import voxeltekImage from "../../../assets/images/voxeltek.png";
import annielearnImage from "../../../assets/images/annielearn.png";
import dinamicAdvImage from "../../../assets/images/dinamic-advance.png";
import { spacing16, spacing8 } from "@litbase/alexandria";
import styled from "styled-components";

export function ReferencesBlock() {
  return (
    <>
      <Subtitle>Custom made websites</Subtitle>
      <TertiaryTitle>
        We built these websites from the ground up, using our self-developed content management system.
      </TertiaryTitle>
      <ShowoffArrowContainer direction={"left"} data={websites} />
      <Subtitle>Mobile apps</Subtitle>
      <TertiaryTitle>Apps we built, or helped build.</TertiaryTitle>
      <ShowoffArrowContainer direction={"right"} data={websites} />
      <Spacing />
    </>
  );
}

const Spacing = styled.div`
  height: ${spacing16};
`;

const websites = [
  { image: aycmImage, name: "All you can move", color: "gray", fontColor: "black" },
  { image: voxeltekImage, name: "Voxeltek", color: "magenta", fontColor: "black" },
  { image: annielearnImage, name: "Annielearn", color: "orange", fontColor: "white" },
  { image: dinamicAdvImage, name: "Dinamic Advance", color: "purple", fontColor: "white" },
];
