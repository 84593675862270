import styled from "styled-components";
import heroPattern from "../../../assets/images/webb.png";
import { animated, useTrail, useSpring } from "react-spring";
import { spacing5, spacing6 } from "@litbase/alexandria";
import { useEffect } from "react";
import { theme } from "../../../themes/theme-types";

export function Hero() {
  const [trail, api] = useTrail("Devolit".length, () => ({
    opacity: 0,
  }));
  const [styles, setStyles] = useSpring(() => ({ opacity: 0 }));
  useEffect(() => {
    api.start({ opacity: 1 })[1].then(() => {
      setStyles({ opacity: 1 });
    });
  }, []);
  return (
    <Body>
      <Title>
        {trail.map((style, index) => (
          <HeroLetterContainer key={index}>
            <HiddenHeroLetter>{"Devolit"[index]}</HiddenHeroLetter>
            <HeroLetter style={style}>{"Devolit"[index]}</HeroLetter>
          </HeroLetterContainer>
        ))}
      </Title>
      <Subtitle style={styles}>Custom made software, delivered quickly and well.</Subtitle>
    </Body>
  );
}

const HeroLetterContainer = styled.div`
  position: relative;
`;

const HeroLetter = styled(animated.span)`
  display: block;
  font-size: 8.5rem;
  overflow: hidden;
  position: absolute;
  top: 0;
`;

const HiddenHeroLetter = styled(HeroLetter)`
  opacity: 0;
  position: static;
`;

const Subtitle = styled(animated.span)`
  font-size: ${({ theme }) => theme.textXl};
  font-weight: normal;
  margin-top: ${spacing6};
`;

const Title = styled.h1`
  display: flex;
  font-size: ${({ theme }) => theme.text6xl};
  height: 9rem;
  color: ${({ theme }) => theme.primary500};
`;

const Body = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
  font-weight: bold;
  background: linear-gradient(${({ theme }) => theme.gray700}, ${({ theme }) => theme.gray700});
`;
