import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter, Route } from "react-router-dom";
import { WelcomePage } from "./pages/welcome-page";
import { baseGlobalStyle, ModalProvider } from "@litbase/alexandria";
import { LitbaseContext } from "@litbase/react";
import { litbaseClient } from "../litbase-client";
import { theme } from "../themes/theme-types";
import { LandingPage } from "./pages/landing-page/ladning-page";

export function App() {
  return (
    <LitbaseContext.Provider value={litbaseClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <ModalProvider>
            <Route path={"/"} component={LandingPage} exact />
          </ModalProvider>
        </BrowserRouter>
      </ThemeProvider>
    </LitbaseContext.Provider>
  );
}

const GlobalStyle = createGlobalStyle`
  ${baseGlobalStyle};
  
  body {
    background: white;
    margin: 0;
    padding: 0; 
  }
  
`;

const StyledApp = styled.div`
  font-weight: bold;
`;
