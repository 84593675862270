import { LitbaseClient } from "@litbase/client";
import {
    ControllerInterface,
    myAppNamespace,
    myAppPromiseFunctions
} from "@my-app/common/controllers/controller-interface";

if (!process.env.SERVER_BASE_URL) throw new Error("SERVER_BASE_URL is not defined");

export const litbaseClient = new LitbaseClient(getWebsocketUrl(process.env.SERVER_BASE_URL), { debug: true });
litbaseClient.connect();

export const controller = litbaseClient.getProxy<ControllerInterface>(
    myAppNamespace,
    myAppPromiseFunctions
);

function getWebsocketUrl(httpUrl: string) {
    const url = new URL(httpUrl);

    if (url.protocol === "http:") {
        url.protocol = "ws:";
    } else {
        url.protocol = "wss:";
    }

    url.pathname = "/ws";
    return url.toString();
}
