import styled from "styled-components";
import { size120, spacing12, spacing3, spacing6, spacing8 } from "@litbase/alexandria";

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: ${({ theme }) => theme.text5xl};
  color: ${({ theme }) => theme.primary500};
  font-weight: normal;
  margin-top: ${spacing8};
`;

export const Subtitle = styled.h2`
  margin-top: ${spacing12};
  color: ${({ theme }) => theme.primary400};
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-size: ${({ theme }) => theme.textXl};
`;

export const TertiaryTitle = styled.span`
  color: rgba(255, 255, 255, 0.75);
  font-size: ${({ theme }) => theme.textSm};
  font-weight: normal;
  max-width: ${size120};
  margin: 0 auto ${spacing6};
  display: block;
  text-align: center;
`;
