import { baseTheme } from "@litbase/alexandria";

export const theme = {
  ...baseTheme,
  primary50: "#fff5f2",
  primary100: "#ffebe6",
  primary200: "#fecec0",
  primary300: "#fdb099",
  primary400: "#fc744d",
  primary500: "#fb3901",
  primary600: "#e23301",
  primary700: "#bc2b01",
  primary800: "#972201",
  primary900: "#7b1c00",
};
