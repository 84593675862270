import styled, { css } from "styled-components";
import {
  size120,
  size140,
  size32,
  size48,
  size64,
  size88,
  size96,
  spacing1,
  spacing16,
  spacing2,
  spacing20,
  spacing4,
  spacing40,
  spacing48,
  spacing6,
  spacing8,
  useRefValue,
} from "@litbase/alexandria";
import { up, down } from "styled-breakpoints";
import { ComponentProps, createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { animated, useTrail, useTransition } from "react-spring";

interface ShowoffData {
  image: string;
  name: string;
  color: string;
  fontColor: string;
}

const ArrowContext = createContext<{ direction: "left" | "right" }>({ direction: "right" });

const startConfig = { mass: 25, tension: 450, friction: 26, clamp: true };
const endConfig = { mass: 15, tension: 550, friction: 100, clamp: false };

export function ShowoffArrowContainer({ data, direction }: { data: ShowoffData[]; direction: "left" | "right" }) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const transition = useTransition(activeIndex !== null ? [1] : [], {
    from: {
      transform: `translateX(${direction !== "left" ? "-" : ""}100vw)`,
    },
    enter: {
      transform: "translateX(0vw)",
      delay: 500,
    },
    leave: {
      transform: `translateX(${direction !== "left" ? "" : "-"}100vw)`,
    },
  });
  const childData = useMemo(() => {
    return direction === "right" ? [...data].reverse() : data;
  }, [direction, data]);

  const [trail, api] = useTrail(childData.length, () => {
    return {
      transform: "translateX(0vw)",
      config: startConfig,
    };
  });

  return (
    <Body $direction={direction}>
      {transition((style) => (
        <Content style={style}>
          <div
            onClick={() => {
              setActiveIndex(null);
              api.start({ transform: `translateX(${direction === "left" ? "" : "-"}100vw)`, immediate: true });
              api.start({
                transform: "translateX(0vw)",
                config: endConfig,
              });
            }}
          >
            Back
          </div>
          <h1>All you can move</h1>
          <p>Good great website</p>
        </Content>
      ))}
      <ArrowContext.Provider value={{ direction }}>
        {childData.map((elem, index) => {
          const styleIndex =
            index === activeIndex ? childData.length - 1 : index === childData.length - 1 ? activeIndex : index;
          return (
            <ShowoffArrow
              key={index}
              data={elem}
              style={trail[index]}
              delay={styleIndex === childData.length - 1 ? 10000 : 0}
              onClick={() => {
                setActiveIndex(index);
                api.start({ transform: `translateX(${direction === "left" ? "-" : ""}100vw)`, config: startConfig });
              }}
            />
          );
        })}
      </ArrowContext.Provider>
    </Body>
  );
}

export function ShowoffArrow({
  data: { color, fontColor, image, name },
  ...props
}: ComponentProps<typeof animated.div> & { data: ShowoffData }) {
  const { direction } = useContext(ArrowContext);
  return (
    <ArrowBody {...props}>
      <ArrowContentWrapper $direction={direction}>
        <ArrowShadowTop $direction={direction} />
        <ArrowShadowBottom $direction={direction} />
        <ArrowContent style={{ background: color, color: fontColor }} $direction={direction}>
          <ArrowTitle $direction={direction} style={{ backgroundColor: color }}>
            {name}
          </ArrowTitle>
          <ArrowBackground $direction={direction} style={{ backgroundImage: `url("${image}")` }} />
        </ArrowContent>
      </ArrowContentWrapper>
    </ArrowBody>
  );
}

const imageUrl = "https://dashgamer.com/wp-content/uploads/2020/10/tenkikey-1400x936.jpg";

const ArrowContentWrapper = styled.div<{ $direction: "left" | "right" }>`
  position: absolute;
  height: 100%;
  ${up("lg")} {
    ${({ $direction }) => ($direction === "right" ? "left: 0;" : "right: 0;")}
  }
`;

const titleHeight = spacing8;

const ArrowTitle = styled.span<{ $direction: "left" | "right" }>`
  width: 75%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${titleHeight};

  ${({ $direction }) =>
    $direction === "left" &&
    `
    margin-left: auto;
  `}

  ${down("md")} {
    position: absolute;
    right: -7px;
    top: 35px;
    transform: rotate(-20deg);
    width: 53%;
    clip-path: polygon(-2% 0, 14% 90%, 100% 100%, 100% 0);
    z-index: 900;
  }
`;

const arrowPadding = spacing2;

const arrowPointyness = spacing16;

const widthTransition = "width ease 0.3s, height ease 0.3s";

const arrowClip = css<{ $direction: "left" | "right" }>`
  ${({ $direction }) =>
    $direction === "right"
      ? `
    clip-path: polygon(
      0 0,
      calc(100% - ${arrowPointyness}) 0,
      100% 50%,
      calc(100% - ${arrowPointyness}) 100%,
      0 100%,
      ${arrowPointyness} 50%
    );
  `
      : `
    clip-path: polygon(
      0 50%,
      ${arrowPointyness} 100%,
      100% 100%,
      calc(100% - ${arrowPointyness}) 50%,
      100% 0%,
      ${arrowPointyness} 0
    );
  `}

  ${down("md")} {
    clip-path: polygon(
      0 0,
      0 calc(100% - ${arrowPointyness}),
      50% 100%,
      100% calc(100% - ${arrowPointyness}),
      100% 0,
      50% ${arrowPointyness}
    );
  }
`;

const ArrowShadowBase = styled.div`
  width: ${spacing4};

  position: absolute;
  box-shadow: 0.5rem 0.5rem 1rem;
`;

const ArrowShadowTop = styled(ArrowShadowBase)<{ $direction: "left" | "right" }>`
  right: 2.5rem;
  transform: rotate(-15deg);
  top: -0.1rem;
  height: 52%;

  ${({ $direction }) =>
    $direction === "left" &&
    `
    transform: rotate(195deg);
    left: 2rem;
    right: initial;
  `}

  ${down("md")} {
    right: 1.7rem;
    transform: rotate(-20deg);
    bottom: -2.6rem;
    width: 47.8%;
    height: 2rem;
    top: initial;
  }
`;

const ArrowShadowBottom = styled(ArrowShadowBase)<{ $direction: "left" | "right" }>`
  right: 2.15rem;

  transform: rotate(16deg);
  bottom: 1.1rem;
  height: 48%;

  ${({ $direction }) =>
    $direction === "left" &&
    `
    transform: rotate(-196deg);
    left: 2rem;
    right: initial;
  `}

  ${down("md")} {
    left: -0.2rem;
    transform: rotate(20deg);
    bottom: -2rem;
    width: 54%;
    height: 2rem;
    top: initial;
  }
`;

const ArrowContent = styled.div<{ $direction: "left" | "right" }>`
  width: ${size64};
  position: relative;
  padding: ${arrowPadding};

  transition: ${widthTransition};
  height: 100%;
  ${arrowClip};
  display: flex;
  flex-direction: column;

  ${down("md")} {
    height: 14rem;
    width: ${size88};
  }
`;

const Content = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: ${size140};
  color: white;
`;

const bgOffset = "0.6rem";

const ArrowBackground = styled.div<{ $direction: "left" | "right" }>`
  background-size: cover;
  background-position: center;
  position: absolute;
  height: calc(100% - ${arrowPadding} * 2);
  width: calc(100% - ${arrowPadding} * 2);
  clip-path: ${({ $direction }) =>
    $direction === "right"
      ? `
    polygon(
    calc(2px + ${bgOffset}) ${titleHeight},
    calc(100% - ${arrowPointyness} + ${bgOffset}) ${titleHeight},
    100% 50%,
    calc(100% - ${arrowPointyness}) 100%,
    3px 100%,
    calc(${arrowPointyness} + 3px) 50%
  );
  `
      : `
    polygon(
      0 50%,
      ${arrowPointyness} 100%,
      100% 100%,
      calc(100% - ${arrowPointyness}) 50%,
      calc(100% - ${bgOffset}) ${titleHeight},
      calc(${arrowPointyness} - ${bgOffset}) ${titleHeight}
  );
  `};
  box-shadow: 1px 1px black;

  ${down("md")} {
    clip-path: polygon(
      0 3px,
      0 calc(100% - ${arrowPointyness} + 3px),
      50% calc(100% + 0px),
      100% calc(100% - ${arrowPointyness}),
      100% 3px,
      50% ${arrowPointyness}
    );
  }
`;

const ArrowBody = styled(animated.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 11.3rem;
  overflow: visible;
  transition: ${widthTransition};
  position: relative;
  cursor: pointer;
  &:hover {
    ${ArrowContent} {
      ${up("lg")} {
        width: ${size88};
      }
      ${down("md")} {
        // height: ${size88};
      }
    }
  }

  ${down("md")} {
    width: 100%;
    height: 9.5rem;
    align-items: center;
  }
`;

const Body = styled.div<{ $direction: "left" | "right" }>`
  display: flex;
  flex-direction: ${({ $direction }) => ($direction === "right" ? "row-reverse" : "row")};
  height: ${size120};
  width: 100%;
  justify-content: center;
  position: relative;
  overflow-x: hidden;

  ${({ $direction }) =>
    $direction === "left" &&
    `
  `}

  ${down("md")} {
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
    padding-bottom: ${spacing20};
  }
`;
