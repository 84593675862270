import styled from "styled-components";
import {
  size1,
  size12,
  size140,
  size24,
  size28,
  size32,
  size36,
  spacing1,
  spacing12,
  spacing16,
  spacing3,
  spacing32,
  spacing4,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import imiImg from "../../../assets/images/imi.png";
import arturImg from "../../../assets/images/artur.png";
import benceImg from "../../../assets/images/bence.png";
import balintImg from "../../../assets/images/balint.png";
import tibiImg from "../../../assets/images/tibi.png";
import { animated, useTrail } from "react-spring";
import { useEffect, useState } from "react";

interface TeamElemData {
  name: string;
  title: string;
  image: string;
  about: string;
}

export function TeamBlock() {
  return (
    <Body>
      {teamData.map((elem, index) => (
        <TeamElem key={index} data={elem} reverse={index % 2 === 1} />
      ))}
    </Body>
  );
}

function TeamElem({ data, reverse }: { data: TeamElemData; reverse: boolean }) {
  const [active, setActive] = useState(false);
  return (
    <TeamElemBody $reverse={reverse} onMouseOver={() => setActive(true)} onMouseOut={() => setActive(false)}>
      <AboutComponent text={data.about} active={active} />
      <Arrow reverse={reverse} />
      <Arrow reverse={reverse} />
      <MainCol>
        <Name>{data.name}</Name>
        <Title>{data.title}</Title>
      </MainCol>
      <ImageArrow reverse={reverse} />
      <Image $reverse={reverse} src={data.image} />
    </TeamElemBody>
  );
}

function Arrow({ reverse, className }: { reverse: boolean; className?: string }) {
  return (
    <ArrowBody $reverse={reverse} className={className}>
      <ArrowTop />
      <ArrowBottom />
    </ArrowBody>
  );
}

function AboutComponent({ text, active }: { text: string; active: boolean }) {
  return (
    <AboutContainer>
      <About>{text}</About>
    </AboutContainer>
  );
}

const AboutContainer = styled.div`
  width: 0;
  transition: width 0.3s ease;
  overflow: hidden;
  position: relative;
`;

const About = styled.div`
  width: ${size32};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: ${({ theme }) => theme.textXs};
  margin: 0 ${spacing4};
`;

const Image = styled.img<{ $reverse: boolean }>`
  height: ${size24};
  width: ${size28};
  object-fit: cover;
  clip-path: ${({ $reverse }) =>
    $reverse
      ? `polygon(0 0%, 0 100%, calc(100% - 0.8rem) 100%, 100% 50%, calc(100% - 0.8rem) 0)`
      : `polygon(0 50%, 0.8rem 100%, 100% 100%, 100% 0, 0.8rem 0)`};
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.textXs};
  color: ${({ theme }) => theme.gray200};
`;

const Name = styled.div`
  font-size: ${({ theme }) => theme.textLg};
`;

const MainCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${spacing32};
`;

const ArrowBody = styled(animated.div)<{ $reverse: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 ${spacing1};
  ${({ $reverse }) => $reverse && `transform: scaleX(-1);`}
`;

const ArrowBase = styled.div`
  height: ${size12};
  width: ${size1};
  background: ${({ theme }) => theme.primary500};
`;

const ArrowBottom = styled(ArrowBase)`
  transform: skewX(15deg);
`;

const ArrowTop = styled(ArrowBase)`
  transform: skewX(-15deg);
`;

const ImageArrow = styled(Arrow)<{ reverse: boolean }>`
  transform: translateX(${({ reverse }) => (reverse ? "-" : "")}11px)
    ${({ reverse }) => (reverse ? "scaleX(-1)" : "scaleX(1)")};
`;

const TeamElemBody = styled.div<{ $reverse: boolean }>`
  color: white;
  display: flex;
  margin-bottom: ${spacing12};
  &:hover {
    ${Image} {
      margin-left: ${spacing8};
      margin-right: ${spacing8};
      transition: margin-left 0.3s ease, margin-right 0.3s ease;
    }
    ${AboutContainer} {
      width: ${size36};
    }
  }
  ${({ $reverse }) => ($reverse ? "flex-direction: row-reverse;margin-right: 4.4rem" : "margin-left: 4.4rem;")}
`;

const Body = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: ${spacing8};
`;

const teamData = [
  { name: "Imi", title: "Knower of things", image: imiImg, about: "Knows things" },
  {
    name: "Artúr",
    title: "Beastmaster",
    image: arturImg,
    about: "He's got the pets, he's got the skills.",
  },
  { name: "Bence", title: "Hustler", image: benceImg, about: "You need something done? He's your guy." },
  { name: "Bálint", title: "Interior decorator", image: balintImg, about: "The guy with the mac." },
  { name: "Tibi", title: "War hero", image: tibiImg, about: "He's been through stuff." },
];
